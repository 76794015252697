<template>
    <layout>
        <v-row
            align="center"
            justify="center"
            class="mt-6"
            v-for="(section, index) in sections"
            :key="index"
        >
            <v-card               
                width="500"
            >   
                <v-card-title class="white--text pl-4 primary">
                    {{ section.label }}
                </v-card-title>
                <v-row 
                    align="center"
                    justify="center"
                    v-for="(field, key) in sectionFields(index)"
                    :key="key"
                    class="px-4"                     
                >
                    <v-col
                        cols="12"                
                    >  
                        <v-text-field
                            v-if="field.type == 'text' || field.type == 'phone' || field.type == 'email' || field.type == 'number'"                                                                               
                            v-bind:label="field.label"
                            v-model="familyDataRecords[index+1][field.model]"
                            @blur="validate(field.model, index+1)"
                            :prepend-icon="field.icon" 
                            :ref="field.ref"
                            :disabled="field.disabled"
                            :filled="field.disabled"
                            :prefix="phonePrefix(field.type)"
                            autocomplete="none"
                            :maxlength="field.maxlength"
                            :onkeypress="isNumber(field.type)"
                            :hint="field.hint"
                            :rules="field.rules"
                            persistent-hint
                                                                      
                        >
                            <template v-slot:append>

                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>

                            </template>
                        </v-text-field>

                        <v-select
                            v-else-if="field.type == 'select'"
                            v-bind:label="field.label"
                            v-model="familyDataRecords[index+1][field.model]"
                            @input="validate(field.model, index+1)"
                            :items="field.items"
                            item-text="detail"
                            item-value="id"
                        >
                            <template v-slot:append>

                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>

                            </template>
                        </v-select>

                    </v-col>
                </v-row>
            </v-card>
        </v-row>                     

    </layout>    
</template>

<script>
import Layout from './layouts/Registration';
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'FamilyData',
    components: {
        Layout,
    },

    created: function () { 
        this.initialize();
    },

    data: () => ({        
        studentRecord: {},            
        dataFields: [],
        familyDataRecords: [],                 
                
    }),    
   
    computed: {       
        regex () {
            return new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) 
        },
       
        familyDemographicFields () {
            return this.otherFields.filter( field => field.category == 'family_demographic');
        },

        ...mapGetters({
            //
        }),
        
        sections () {
            //console.log(this.dataFields);
            let sections = [];
            
            this.dataFields.forEach(fieldArray => {
                fieldArray.filter(field => {
                    if(field.break){
                        sections.push(field);
                        return field;
                    }
                })
            })
           
            return sections;
        },
            
    },  
    
    methods: { 

        ...mapMutations({
            setOverlay: 'auth/setOverlay',
            setDataFamily: 'form/setStudentDataFamily',
            setDataPersonal: 'form/setStudentDataPersonal',
        }),

        ...mapActions({
            getDataFamily: 'form/getStudentDataFamily',
            getDataPersonal: 'form/getStudentDataPersonal',
            getLivingStatus: 'form/getLivingStatus',  
            postDataFamily: 'form/postStudentDataFamily',
            postDataPersonal: 'form/postStudentDataPersonal',      
        }),

        async initialize () {
            this.setOverlay(true);
            try {
                const promiseLivingStatus = this.getLivingStatus();
                const promiseDataFamily = this.getDataFamily();
                const promiseDataPersonal = this.getDataPersonal();

                const [
                    { data: dataLivingStatus },
                    { data: dataFamily },
                    { data: dataPersonal }
                ] = await Promise.all([
                    promiseLivingStatus,
                    promiseDataFamily,
                    promiseDataPersonal,
                ]);

                this.mapStudentRecord(dataPersonal);
                this.setDataFields(dataFamily);
                this.mapLivingStatus(dataLivingStatus)

            } catch (error) {
                if(error.response){
                    console.log(error.response);
                }
                else console.log(error);
            }
            this.setOverlay(false);
        },

        mapStudentRecord (data) { 
            this.studentRecord = {...data}
        },

        setDataFields (val) {
            const fields = {
                relationship : null,
                name: null,
                marital_status: null,
                address_home: null,
                occupation: null,
                employer: null,
                address_work: null,
                phone_home: null,
                phone_work: null,
                phone_mobile: null,
                email: null,
                id_card: null,
                relation: null,
                student_id: this.studentRecord.student_id,
            }           
            
            const modelSections = [null, 'father', 'mother', 'guardian', 'emergency', 'demographics']
            modelSections.forEach( (value, index) => {
                let record = {};
                if(value != null && val[index]) {
                    record = {...val[index]};
                }                
                else if(value != null && value != 'demographics'){
                    Object.keys(fields).forEach(key => {
                        if(key === 'relationship')
                        record[key] = index;
                        else record[key] = fields[key];
                    })
                }
                else if(value != null){
                    record['living_status_id'] = this.studentRecord['living_status_id'];
                    record['no_in_family'] = this.studentRecord['no_in_family'];
                    record['no_at_home'] = this.studentRecord['no_at_home'];
                    record['place_in_family'] = this.studentRecord['place_in_family'];
                }
                            
                this.familyDataRecords[index] = record;
            })
            //console.log(this.familyDataRecords);           
            
            const familySections = [ 'Father', 'Mother', 'Guardian', 'Emergency Contact', 'Family Demographics'];

            let sectionDataFields = [
                {
                    label: 'Name', 
                    model: 'name', 
                    type: 'text', 
                    disabled: false,
                    filled: false,
                    required: false,
                    ref: null,
                    section: null,
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                },
                {
                    label: 'Marital Status', 
                    model: 'marital_status', 
                    type: 'select', 
                    disabled: false,
                    filled: false,
                    required: false,
                    ref: null,
                    section: null,
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                    items: [
                        {id: 'Married', detail:'Married'},
                        {id: 'Single', detail:'Single'},
                        {id: 'Divorced', detail:'Divorced'},
                        {id: 'Widowed', detail:'Widowed'}
                    ]
                },
                {
                    label: 'Address', 
                    model: 'address_home', 
                    type: 'text', 
                    disabled: false,
                    filled: false,
                    required: false,
                    ref: null,
                    section: null,
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                },
                {
                    label: 'Occupation', 
                    model: 'occupation', 
                    type: 'text', 
                    disabled: false,
                    filled: false,
                    required: false,
                    ref: null,
                    section: null,
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                },
                {
                    label: 'Employer / Company', 
                    model: 'employer', 
                    type: 'text', 
                    disabled: false,
                    filled: false,
                    required: false,
                    ref: null,
                    section: null,
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                },
                {
                    label: 'Work Address', 
                    model: 'address_work', 
                    type: 'text', 
                    disabled: false,
                    filled: false,
                    required: false,
                    ref: null,
                    section: null,
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                },
                {
                    label: 'Phone (Home)', 
                    model: 'phone_home',
                    hint: '7 digits only', 
                    type: 'phone', 
                    disabled: false,
                    filled: false,
                    required: false,
                    ref: null,
                    section: null,
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                    maxlength: 7,
                    rules: [ value => (value || '').length == 7 || '7 Digits Needed']
                },
                {
                    label: 'Phone (Work)', 
                    model: 'phone_work',
                    hint: '7 digits only',  
                    type: 'phone', 
                    disabled: false,
                    filled: false,
                    required: false,
                    ref: null,
                    section: null,
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                    maxlength: 7,
                    rules: [ value => (value || '').length == 7 || '7 Digits Needed']
                },
                {
                    label: 'Phone (Mobile)', 
                    model: 'phone_mobile', 
                    type: 'phone',
                    hint: '7 digits only',  
                    disabled: false,
                    filled: false,
                    required: false,
                    ref: null,
                    section: null,
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                    maxlength: 7,
                    rules: [ value => (value || '').length == 7 || '7 Digits Needed']
                },
                {
                    label: 'Email Address', 
                    model: 'email', 
                    type: 'email', 
                    disabled: false,
                    filled: false,
                    required: false,
                    ref: null,
                    section: null,
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                    rules: [value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    }]
                },
                {
                    label: 'ID Card #', 
                    model: 'id_card', 
                    type: 'text', 
                    disabled: false,
                    filled: false,
                    required: false,
                    ref: null,
                    section: null,
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                },
                {
                    label: 'Parent(s) / guardian the student lives with', 
                    model: 'living_status_id',                    
                    type: 'select',
                    disabled: false,
                    filled: false,
                    required: true,
                    ref: 'livingStatus',
                    category: 'family_demographic',
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                    items: [],
                    text: 'detail',
                    value: 'id'
                },
                {
                    label: 'Number of Children in Family',
                    type: 'number', 
                    model: 'no_in_family',
                    hint: 'Number only', 
                    required: false,
                    ref: 'noInFamily',
                    category: 'family_demographic',
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                },
                {
                    label: 'Number of Children at Home', 
                    type: 'number', 
                    model: 'no_at_home',
                    hint: 'Number only',
                    required: false,
                    ref: 'noInHome',
                    category: 'family_demographic',
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                },            
                {
                    label: 'Place in Family', 
                    type: 'number', 
                    model: 'place_in_family',
                    hint: 'Number only',
                    required: false,
                    ref: 'placeInFamily',
                    category: 'family_demographic',
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                },       
               
            ];
           

            familySections.forEach( field => {
                let dataFields = [];
                if(field !== 'Family Demographics'){
                    dataFields.push(
                        { label: field + "'s Information", break: true},
                        //...sectionDataFields.filter(value => (value.category != 'family_demographic'))
                    );
                    sectionDataFields.forEach(value => {
                        if(value.category != 'family_demographic')
                        dataFields.push({...value})
                    })
                }
                else{
                    dataFields.push(
                        { label: field, break: true},                       
                        ...sectionDataFields.filter(value => (value.category == 'family_demographic'))
                    )
                    
                }
                

                if(field === 'Emergency Contact'){
                    dataFields = dataFields.filter(value => {
                        if(
                            value.model != 'marital_status' &&
                            value.model != 'address_home' &&
                            value.model != 'occupation' &&
                            value.model != 'employer' &&
                            value.model != 'address_work' &&
                            value.model != 'email' &&
                            value.model != 'id_card'
                        ) return value;
                    });

                    dataFields.push(
                         {
                            label: 'Relationship', 
                            model: 'relation', 
                            type: 'text', 
                            disabled: false,
                            filled: false,
                            required: false,
                            ref: null,
                            section: null,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                        }
                    )
                }
                dataFields.student_id = this.studentRecord.student_id;
                this.dataFields.push(dataFields);
                //console.log(dataFields);                
            })
           
        },

        mapLivingStatus (data) {
            this.dataFields.forEach(value => {
                value.forEach(field => {
                    if(field.model === 'living_status_id'){
                        field.items = data;
                        return;
                    }
                })
            })
        },

        sectionFields (section) {           
            let fields = [];
            this.dataFields.forEach((fieldArray, index) => {
                if(index == section){
                    fields = fieldArray.filter(field => {                        
                        if(!field.break) return field;
                    })
                }             
            })                             
            return fields;            
        },

        phonePrefix (type) {            
            if(type == 'phone') return '1868';
        },

        fieldType (type) {
            if(type == 'phone' || type == 'number') return 'number';
        },

        isNumber(type){
            if(type == 'phone' || type == 'number') 
            return 'return (event.keyCode >= 48 && event.keyCode <= 57)'
        },
        
        validate (model, index) {
            //console.log(index);            
            let data = this.familyDataRecords[index];
            if(!this.recordIsBlank(data) && data[model]) 
            this.saveRecord(data, index, model);        
                       
        },

        async saveRecord (data, index, model) {
            let response = null;          
            response = await this.setSaveStatus(model, index);            
            try {
                if(index < 5){                    
                    this.setDataFamily(data);
                    response = await this.postDataFamily();
                    console.log(response.status);                   
                }
                else{                    
                    this.studentRecord = Object.assign(this.studentRecord, data);
                    //console.log(this.studentRecord);
                    // console.log(this.studentRecord);
                    this.setDataPersonal(this.studentRecord);
                    // console.log(data);
                    response = await this.postDataPersonal();
                    console.log(response);
                }
                response = await this.setSaveStatus(model, index, 'saved');
                console.log(response);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                await this.setSaveStatus(model, index, 'error');
            }
            //console.log(familyData);
        },

        recordIsBlank (record) {
            let blank = true;
            Object.keys(record).forEach(key => {
                if(record[key] && key != 'relationship'){
                    blank = false;
                    return blank;                    
                } 
            })
            return blank;
        },       

        setSaveStatus (model, index, status = 'saving') {
            //console.log(index);
            //console.log(this.dataFields[index-1]);
            for(const field of this.dataFields[index-1]) {                
                if(field.model == model){                    
                    switch (status){
                        case 'saving':                            
                            field.saveSuccess = false;
                            field.saveError = false;                            
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.save = true;                                                                      
                                    resolve(status);
                                }, 600)
                            })                            
                            
                        case 'saved':
                            field.save = false;
                            field.saveError = false;
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.saveSuccess = true; 
                                    resolve(status);
                                }, 600)
                            })
                            
                        case 'error':
                            field.save = false;
                            field.saveSuccess = false;
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.saveError = true;
                                    resolve(status);
                                }, 600)
                            })
                            
                        default:
                            console.log('no match found');
                            return new Promise(resolve => {
                                resolve('no match');
                            });             
                    }                    
                }
            }
                    
        }       
               
    }
}
</script>