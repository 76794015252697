<template>
    <layout>
        <v-row 
            align="center"
            justify="center"
            class="mt-6"
            v-for="(section, index) in sections"
            :key="index"            
        > 
            <v-card                
                width="500"
               
            >
                <v-row 
                    align="center"
                    justify="center"
                    v-for="(field, key) in sectionFields(index)"
                    :key="key"
                    class="px-4"                   
                >
                    <v-col 
                        cols="8"
                        v-if="field.type == 'switch'"
                    >
                       {{ field.label }}
                    </v-col>

                    <v-col
                        cols="4"
                        v-if="field.type == 'switch'"
                        class="d-flex"
                    >
                        <v-switch
                            v-model="dataRecord[field.model]"
                            @change="update(field.model, index)"
                            :label="(dataRecord[field.model] == true) ? 'Yes' : 'No'"
                        >
                            <template v-slot:append>

                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-switch>
                    </v-col>

                    <v-col cols="12">

                        <v-text-field
                            v-if="field.type == 'text'"
                            v-bind:label="field.label"
                            v-model="dataRecord[field.model]"
                            @blur="update(field.model, index)"
                            :prepend-icon="field.icon"
                            autocomplete="none"
                        >
                            <template v-slot:append>

                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>

                            </template>
                        </v-text-field>

                        <v-select
                            v-else-if="field.type == 'select'"
                            v-bind:label="field.label"
                            v-model="dataRecord[field.model]"
                            @input="update(field.model, index)"
                            :items="field.items"
                            :prepend-icon="field.icon"
                        >
                            <template v-slot:append>

                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>

                            </template>
                        </v-select>

                    </v-col>
                </v-row>
            </v-card>

           
        </v-row>      

       
    </layout>    
</template>

<script>
import Layout from './layouts/Registration';
import { mapActions, mapMutations } from 'vuex'

export default {
    name: 'OtherData',
    components: {
        Layout,
    },

    created: function () {
        this.initialize();
    },    
    
    data: () => ({
        dataFields: [],
        dataRecord: {},
    }),

    watch: {
       //
    },

    computed: {
        sections () {            
            let sections = [];
            
            this.dataFields.forEach(fieldArray => {
                fieldArray.filter(field => {
                    if(field.break){
                        sections.push(field);
                        return field;
                    }
                })
            })
            
            return sections;
        },
              
    },

    methods: {
        ...mapMutations({
            setOverlay: 'auth/setOverlay',
            setDataPersonal: 'form/setStudentDataPersonal',                     
        }),

        ...mapActions({
            postDataPersonal: 'form/postStudentDataPersonal',
            getDataPersonal: 'form/getStudentDataPersonal',            
        }),

        async initialize () {
            this.setOverlay(true);
            try {
                const { data } = await this.getDataPersonal();
                await this.setLocalRecords(data);
                this.setDataFields();
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            
            this.setOverlay(false);
        },

        setLocalRecords (data) {
            return new Promise(resolve => {
                setTimeout(() => {
                    this.dataRecord = Object.assign({}, data);                   
                    // console.log(this.dataRecord);                                                      
                    resolve();
                })
            })
        },

        setDataFields () {
            this.dataFields = [
                [
                    { label: '', break: true}, 
                    {
                        label: 'Extra-Curricular Interests',
                        icon: 'mdi-basketball',  
                        type: 'text', 
                        model: 'extra_curricular', 
                        required: false,
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                ],
                [
                    { label: '', break: true}, 
                    {
                        label: 'What type of device does your child have access to?',
                        icon: 'mdi-laptop', 
                        items: ['No Device', 'Phone', 'Laptop', 'Desktop', 'Tablet', 'Other'],
                        type: 'select',
                        model: 'device_type',
                        required: true,
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                ],
                [
                    { label: '', break: true},
                    {
                        label: 'Does your child have internet access?', 
                        type: 'switch', 
                        model: 'internet_access',
                        required: false,
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: 'Would your child require School Feeding Meals?', 
                        type: 'switch', 
                        model: 'school_feeding',
                        required: false,
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: 'Does your child access Social Welfare Grant?', 
                        type: 'switch', 
                        model: 'social_welfare',
                        required: false,
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },                                       
                    // {
                    //     label: 'Would you be requesting a transfer?', 
                    //     type: 'switch', 
                    //     model: 'request_transfer',
                    //     required: false,
                    //     save: false,
                    //     saveSuccess: false,
                    //     saveError: false,
                    // },    
                ]
            ]
        },

        sectionFields (section) {           
            let fields = [];
            this.dataFields.forEach((fieldArray, index) => {
                if(index == section){
                    fields = fieldArray.filter(field => {                        
                        if(!field.break) return field;
                    })
                }             
            })                             
            return fields;            
        },       

        async update (model, index) {            
            let record = {};
            let response = await this.setSaveStatus(model, index);
            console.log(response);
            
            try {                
                
                Object.keys(this.dataRecord).forEach(key => {
                    if(this.dataRecord[key] === true )
                    record[key] = 1;
                    else if (this.dataRecord[key] === false )
                    record[key] = 0;
                    else record[key] = this.dataRecord[key];
                })
                console.log(record);                   
                this.setDataPersonal(record);  
                response = await this.postDataPersonal();
                console.log(response.status);

                response = await this.setSaveStatus(model, index, 'saved');
                console.log(response);

            } catch (error) {
                if(error.response)
                console.log(error.response);
                else console.log(error);
                await this.setSaveStatus(model, index, 'error'); 
            }
        },

        setSaveStatus (model, index, status = 'saving') {            
            for(const field of this.dataFields[index]) {                
                if(field.model == model){                    
                    switch (status){
                        case 'saving':                            
                            field.saveSuccess = false;
                            field.saveError = false;                            
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.save = true;                                                                      
                                    resolve(status);
                                }, 600)
                            })                            
                            
                        case 'saved':
                            field.save = false;
                            field.saveError = false;
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.saveSuccess = true; 
                                    resolve(status);
                                }, 600)
                            })
                            
                        case 'error':
                            field.save = false;
                            field.saveSuccess = false;
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.saveError = true;
                                    resolve(status);
                                }, 600)
                            })
                            
                        default:
                            console.log('no match found');
                            return new Promise(resolve => {
                                resolve('no match');
                            });             
                    }                    
                }
            }
                    
        }
        
    }
}
</script>